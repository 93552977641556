<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      Team: "Equipe",
      Username: "Usuário",
      Name: "Nome",
      Date: "Data",
      Level: "Nível",
      Plano: "Plano",
      Binary: "Binário",
      Status: "Status",
      Qualified: "Qualificado",
      Unqualified: "Não Qualificado",
      Left: "Esquerdo",
      Right: "Direito",
      Pending: "Pendente",
      Approved: "Aprovado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
      Graduation: "Graduação",
    },
    es: {
      Team: "Equipe",
      Username: "Nombre de Usuario",
      Name: "Nombre",
      Date: "Data",
      Level: "Nivel",
      Plano: "Plano",
      Binary: "Binario",
      Status: "Status",
      Qualified: "Calificado",
      Unqualified: "No Cualificado",
      Left: "Izquierdo",
      Right: "Derecho",
      Pending: "Pendiente",
      Approved: "Aprobado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
      Graduation: "Graduación",
    },
  },
  components: {
    Layout,
    VclList
  },
  data() {
    return {
      loading: true,
      errored: false,
      total: 0,
      fields: [
        { key: "username", label: "Usuário", sortable: true },
        { key: "name", label: "Nome", sortable: true },
        { key: "date", label: "Data", sortable: true },
        { key: "level", label: "Nível", sortable: true },
      ],
      items: null,

      sortBy: "name",
      sortDesc: false,
      currentPage: "1",
      perPage: "25",
      periodMonth: new Date(),

      totalUsers: 0,
    };
  },
  methods: {
  },
  mounted() {
    api
      .get("network/team")
      .then((response) => {
        if (response.data.status == "success") {
          this.items = response.data.list;
          this.totalUsers = response.data.total;
        }
      })
      .catch((error) => {
        this.errored = error;
      })
      .finally(() => {
        this.loading = false;
        if (this.body == "" || this.body == null) {
          this.empty = true;
        }
      });
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ t('Equipe') }}</h3>
        <p></p>
      </div>
    </div>

    <div id="dropdown" class="d-flex mb-4">
      <div class="totals">
        <h6 class="m-0">{{ this.totalUsers }}</h6>
        <h6 class="m-0 text-secondary">Total da equipe</h6>
      </div>
      <b-dropdown variant="outline-light">
        <template #button-content>
          Itens por página
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-form><b-form-radio v-model="perPage" value="10">10 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="25">25 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="50">50 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="100">100 itens</b-form-radio></b-dropdown-form>
      </b-dropdown>
    </div>

    <div class="card">
      <div class="card-body min-vh-50">
        <div class="error text-center min-vh-50" v-if="errored">
          Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.
        </div>
        <vcl-list v-else-if="loading" class="col-md-6"></vcl-list>
        <div v-else-if="items && items.length == 0" class="empty text-center min-vh-50">
          <strong>Nenhuma registro encontrado.</strong>
        </div>
        <div v-else class="table-responsive">
          <b-table :items="items" :fields="fields" class="table-nowrap table-style table-hover min-vh-50" head-variant="light" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :current-page="currentPage" :per-page="perPage" :filter="filterBy" :filter-included-fields="filterOn" responsive>
            <template #cell(avatar)="row">
              <img v-if="row.item.avatar" class="rounded-circle header-profile-user" :src="row.item.avatar" alt="" style="width: 40px; height: 40px" />
              <img v-else class="rounded-circle header-profile-user" :src="'https://ui-avatars.com/api/?background=580B75&color=fff&name=' + row.item.name" alt="" style="width: 40px; height: 40px" />
            </template>
            <template #cell(package)="row">
              <span class="badge badge-soft-dark font-size-12">{{
                row.item.package
                }}</span>
            </template>
            <template #cell(monthly)="row">
              <span v-if="row.item.monthly === 'monthly_inactive'" class="badge badge-soft-danger font-size-12">INATIVO</span>
              <span v-else-if="row.item.monthly === 'monthly_active'" class="badge badge-soft-success font-size-12">ATIVO</span>
            </template>
            <template #cell(graduation)="row">
              <img v-if="row.item.graduation.image" class="rounded-circle header-profile-user" :src="require('@/assets/images/graduations/' + row.item.graduation.image)
                " alt="" style="width: 40px; height: 40px" />
              <img v-else class="rounded-circle header-profile-user" src="@/assets/images/logo-icon.png" alt="" style="width: 40px; height: 40px" />
              <span class="badge font-size-12">{{ row.item.graduation.name }}</span>
            </template>
            <template #cell(status)="row">
              <span v-if="row.item.status === 'Pending'" class="badge badge-soft-warning font-size-12">{{ t(row.item.status) }}</span>
              <span v-else-if="row.item.status === 'Approved'" class="badge badge-soft-success font-size-12">{{ t(row.item.status) }}</span>
            </template>
          </b-table>
        </div>
        <b-pagination v-if="totalUsers > 0" class="m-0 mt-3" v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" pills align="center"></b-pagination>
      </div>
    </div>
  </Layout>
</template>
